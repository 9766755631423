import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function GameStats ({ game }) {
    const { gameStats } = game;
    return <div className="gameStats">
        {gameStats.score === "Early Access" 
        ? <div style={{maxWidth: "10ch"}}>
                <h5>{gameStats.score}</h5>
        </div>
        : <div>
            <h5>{gameStats.score} <FontAwesomeIcon icon={faStar} /></h5>
            <p>{gameStats.reviewers} Reviews</p>
        </div> 
        }
        <div>
            <h5>{gameStats.downloads}</h5>
            <p>Downloads</p>
        </div>
    </div>;
}

export default GameStats;