import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faStar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby"

import GameStats from "./GameStats";

function Games({ games }) {
    return <div className="gamesList">
        {games.map(({ node: game }) => <div key={game.path} className="game">
            <div>
                <img src={game.featureImage} alt={game.name} />
            </div>
            <div className="gameInfo">
                <div className="gameHeader">
                    <img src={game.appIcon} alt={game.name} />
                    <div className="gameMeta">
                        <h5 className="gameType">{game.category}</h5>
                        <h1 className="gameName">{game.name}</h1>
                    </div>
                </div>
                <GameStats game={game} />
                <p className="gameDesc">{game.description}</p>
                <div>
                    <Link to={game.path}>
                        <button>
                            <FontAwesomeIcon icon={faCaretRight} /> Read more...
                        </button>
                    </Link>
                </div>
            </div>
        </div>)}
    </div>
}

export default Games