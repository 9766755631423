import React from "react"
import { graphql } from "gatsby"

import SEO from "../../Components/SEO"
import Nav from "../../Components/Nav"
import GamesLanding from "../../Components/GamesLanding"
import Games from "../../Components/Games"
import Footer from "../../Components/Footer"


function GamesPage({ data }){
  const { edges: games } = data.allGamesJson
  return(
    <main className="gamesPage">
      <SEO title="Funovus Games" keywords="wild sky, wild castle, Loop & Loot™, merge war, grow castle, Strategy games, TD games, RTS games" />
      <Nav />
      <GamesLanding />
      <Games games={games} />
      <Footer />
    </main>
  )
}

export default GamesPage

export const pageQuery = graphql`
  query GameQuery {
    allGamesJson (sort: { order: ASC, fields: order }) {
      edges {
        node {
          path
          name
          appIcon
          category
          featureImage
          description
          gameStats {
            score
            reviewers
            downloads
          }
          social {
            facebook
            instagram
            youtube
          }
        }
      }
    }
  }
`